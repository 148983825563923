import React from "react";

import brand1 from "../../assets/image/ffg_logo_de.png";
import brand2 from "../../assets/image/as-logo.png";
import brand3 from "../../assets/image/zero21_logo.png";
import brand4 from "../../assets/image/lawfinder_logo.png";

const link1 = "https://www.ffg.at/";
const link2 = "https://austrianstartups.com/";
const link3 = "https://zero21.club/";
const link4 = "https://www.lawfinder.at/";

const SingleBrand = ({ image, link }) => {
  return (
    <>
      {/* Single Brand */}
      <a href={link} target="_blank" rel="noopener noreferrer" className="single-brand-logo mx-8 my-10">
        <img
          className="max-w-140"
          src={image}
          alt=""
          data-aos="fade-in"
          data-aos-once="true"
        />
      </a>
      {/* Single Brand */}
    </>
  );
};

const Supporters = ({ className, ...rest }) => {
  const images = [
    { link: link1, img: brand1 },
    { link: link2, img: brand2 },
    { link: link3, img: brand3 },
    { link: link4, img: brand4 },
  ];

  return (
    <>
      {/* Company Section */}
      <div className={className} {...rest}>
        <div className="container-wide">
          {/* Brand Logos */}
          <div className="d-flex align-items-center justify-content-center mb-8">
            <div
              className="border-top border-width-2"
              style={{ flex: 1 }}
            ></div>
            <div className="font-size-5 text-center mx-3 heading-default-color">
              unterstützt von
            </div>
            <div
              className="border-top border-width-2"
              style={{ flex: 1 }}
            ></div>
          </div>
          <div className="brand-logo-small d-flex align-items-center justify-content-around flex-wrap">
            {images.map((item, index) => (
              <SingleBrand image={item.img} link={item.link} key={index} />
            ))}
          </div>
        </div>
      </div>
      {/* End Company Section */}
    </>
  );
};

export default Supporters;
